$(document).ready(function(){
  $( "#check_user_role" ).click(function() {
    var email = $("#bcs_admin_user_email").val();
    $.ajax({
      url: "/check_user_role",
      type: "GET"  ,
      contentType: 'application/json',
      data: {user_id: email},
      success:function(data){
        if(data == true){
          $("#js-login-form").submit();
        }else{
          $("#user_password").val("");
           toastr.error(I18n.t('common.not_auth_user'));
        }
      }
    });
  });
});