
// =========================================== function start ======================================//

$(document).ready(function(){
  $.fn.dataTable.ext.errMode = function ( settings, helpPage, message ) {console.log(message)};
  fnToggleExpandCollapse()
  fnTwoWayAuth(window.fes_twf)
  if (window.fes_uid == null){delCookie('MonjuTwoFactorIgnore')}
  if(window.fes_twf == 'true' || getCookie('MonjuTwoFactorIgnore') == 'Yes' || getCookie('MonjuTwoFactor_Mg') == '==No'){
    fnLastPasswordUpdate(window.fes_user_last_password_at)
   }
});

$(window).on("load", function () {
  setTimeout(function(){$(".loader-container").hide();}, 1000); // page is loaded and 1 second has passed
});

$(document).ajaxComplete(function( event, xhr, settings ) {
  if (xhr.responseText != undefined){
    if(settings.dataType == "json" || settings.dataTypes[1] == "script"|| settings.dataType == "script *" || settings.dataTypes == "text") {
      if ((xhr.responseText.includes("login-form")) || (xhr.responseText.includes("Session dump") == true) || (xhr.responseText.includes("login.js") == true) || (xhr.responseText.includes("location.reload();") == true)){
        location.reload();
      }
      else{
      }
    }
  }
});
/**
 * initialize emoji for textfield
 */
fnInitEmoji = function (id, events = {}) {
  $("#" + id).emojioneArea({
    pickerPosition: "bottom",
    tonesStyle: "radio",
    events: events
  });
}

fnInitEmojiViewOnly = function (id, events = {}) {
  $("#" + id).emojioneArea({
    pickerPosition: "bottom",
    tonesStyle: "radio",
    events: events
  });
}

/**
 * Fetch screen title from menu
 */
getScreenTitle = function () {
  var menuTitle = I18n.t('project.name');
  if ($("li.active").find("span").length > 0) {
    var menuParent = $("li.active").find("span").html();
    var menuChild = $(".submenu li.active").find("span").html();
    menuTitle = menuParent + (menuChild == undefined ? "" : (" | " + menuChild));
    $("title").html(menuTitle);
  } else if ($("a.dropdown-item.active").length > 0) {
    menuTitle = $("a.dropdown-item.active").text();
    $("title").html(menuTitle);
  } else {
    $("title").html(menuTitle);
  }
}

/**
 * @function fnSaveDataForm. vishal 2019/04/30
 * save form on click
 */
fnSaveDataForm = function (formid, obj) {
  fnBeforeSaveDataForm(formid, obj);
  return false;
}

/**
 * @function fnBeforeSaveDataForm. vishal 24/01/2019
 * overwrite function local
 */
fnBeforeSaveDataForm = function (formid, obj) {
  fnValidateSaveDataForm(formid, obj);
}

/**
 * @function fnValidateSaveDataForm. vishal 24/01/2019
 * validate form
 */
fnValidateSaveDataForm = function (formid, obj) {
  $("#" + formid).validationEngine('attach', {
    lang: 'ja',
    promptPosition: "topRight",
    scroll: false,
    maxErrorsPerField: 1
  });
  var formvalidate = $("#" + formid).validationEngine('validate');
  if (formvalidate == true && $(".vbn-error-cell").length == 0) {
    $(".loader-container").show();
    fnSubmitForm(formid, obj);
    if (obj) {
      obj.attr("disabled", true);
    }
  } else {
    if (formvalidate == false) {
      $('input[type=checkbox].vbn-error-cell').next('label').addClass('vbn-error-cell');
      $('input[type=checkbox].vbn-error-cell, label.vbn-error-cell').click(function () {
        $(this).removeClass('vbn-error-cell');
      });
    }

  }
}

/**
 * @function fnSubmitForm. vishal 2019/04/30
 * submit form
 * @params formid
 */
fnSubmitForm = function (formid, obj) {
  fnRemoveWhiteSpaceString(formid);
  var form = $("#" + formid);
  //need to remove disabled because its not going to parameters
  form.find("[disabled]").addClass("disabled_field").removeAttr("disabled");
  setTimeout(function () {
    var url = form.attr('action');
    $.ajax({
      type: "POST",
      url: url,
      data: form.serialize(), // serializes the form's elements.
      success: function (data) {
        $(".loader-container").hide();
        console.log(data);
        if (data && (data.error || "") == "true") {
          if ((data.message || "") != "") {
            toastr.error(data.message);
          }
          fnAfterSaveErrorDataForm({
            "formid": formid,
            "obj": obj,
            "data": data
          });
        } else if (data && (data.success || "") == "true") {
          if ((data.message || "") != "") {
            toastr.success(data.message);
          }
          fnAfterSaveDataForm({
            "formid": formid,
            "obj": obj,
            "data": data
          });
        } else if (data && (data.info || "") == "true") {
          if ((data.message || "") != "") {
            toastr.success(data.message);
          }
          fnAfterSaveInfoDataForm({
            "formid": formid,
            "obj": obj,
            "data": data
          });
        } else if (data && (data.warning || "") == "true") {
          if ((data.message || "") != "") {
            toastr.success(data.message);
          }
          fnAfterSaveWarningDataForm({
            "formid": formid,
            "obj": obj,
            "data": data
          });
        }
        $(".disabled_field").removeClass("disabled_field").attr("disabled", true);
        $('input.numeric-format, input.decimal-format, input.pincode-format, label.pincode-format').trigger('focusout');
        $(".ajaxloader").removeClass("hide");
      },
      error: function (xhr, desc, err) {
        toastr.error(I18n.t('E0010'));
        $(".disabled_field").removeClass("disabled_field").attr("disabled", true);
        $(".ajaxloader").removeClass("hide");
        fnAfterSaveErrorDataForm({
          "formid": formid,
          "obj": obj
        });
      }
    });
  }, 1500)
}

/**
 * @function fnAfterSaveDataForm. vishal 2019/04/30
 * overwrite function local
 */
fnAfterSaveDataForm = function (data) {
  if (data.obj) {
    fnRemoveSpinnerBorder(data.obj.attr("id"));
    data.obj.attr("disabled", false);
  }
  return true;
}

/**
 * @function fnAfterSaveErrorDataForm. vishal 2019/04/30
 * overwrite function local
 */
fnAfterSaveErrorDataForm = function (data) {
  if (data.obj) {
    fnRemoveSpinnerBorder(data.obj.attr("id"));
    data.obj.attr("disabled", false);
  }
  return true;
}

/**
 * @function fnAfterSaveWarningDataForm. vishal 2020/05/07
 * overwrite function local
 */
fnAfterSaveWarningDataForm = function (data) {
  if (data.obj) {
    fnRemoveSpinnerBorder(data.obj.attr("id"));
    data.obj.attr("disabled", false);
  }
  return true;
}

/**
 * @function fnAfterSaveInfoDataForm. vishal 2020/05/07
 * overwrite function local
 */
fnAfterSaveInfoDataForm = function (data) {
  if (data.obj) {
    fnRemoveSpinnerBorder(data.obj.attr("id"));
    data.obj.attr("disabled", false);
  }
  return true;
}

/**
 * @function fnRemoveSpinnerBorder. vishal 2019/04/30
 * remove white space before submit
 */
fnRemoveSpinnerBorder = function (id) {
  $("#" + id + " .spinner-border").remove();
}

/**
 * @function fnRemoveWhiteSpaceString. vishal 2019/04/30
 * remove white space before submit
 */
fnRemoveWhiteSpaceString = function (formid) {
  $('#' + formid + ' input[type="text"], #' + formid + ' textarea').each(function () {
    $(this).val($(this).val().trim());
  });
}

/**
 * @function fnOpenDialog. vishal 2020/05/07
 * initialize dialog
 * key: identity
 * data: pass custom data
 */
 var dialogue_visble = false; // added by Dinesh to avoid multiple dialogue at a time;
fnOpenDialog = function (key, data = {}) {
  if(dialogue_visble)
    return false;
  dialogue_visble = true;
  $(".loader-container").show();
  $.ajax({
    type: "get",
    url: '/shared/control/open_dialog',
    dataType: 'script',
    data: {
      "key": key,
      "data": data
    },
    success: function (data) {
      setTimeout(function(){$(".loader-container").hide();}, 100);
      dialogue_visble = false;
    }
  });
}

/**
 * @function fnCloseDialog. vishal 2020/05/07
 * close dialog
 */
fnCloseDialog = function (d_id) {
  if ($("#" + d_id).length > 0) {
    $("#" + d_id).modal('toggle');
    $("#" + d_id).remove();
  }
}

/**
 * @function fnCloseDialog. vishal 2020/05/07
 * initialize smart panel
 * options.buttons : create button using fnGetSmartPanelButton function & pass string to object
 */
fnInitSmartPanel = function (smart_panel_id, options = {}) {
  buttons = options.buttons || ""
  $('#' + smart_panel_id).smartPanel({
    localStorage: true,
    onChange: function () { },
    onSave: function () { },
    opacity: 1,
    deleteSettingsKey: '#deletesettingskey-options',
    settingsKeyLabel: 'Reset settings?',
    deletePositionKey: '#deletepositionkey-options',
    positionKeyLabel: 'Reset position?',
    sortable: true,
    buttonOrder: '%collapse% %fullscreen% %close%',
    buttonOrderDropdown: '%refresh% %locked% %color% %custom% %reset% ' + buttons,
    customButton: false,
    customButtonLabel: "Custom Button",
    onCustom: function () { },
    closeButton: false,
    onClosepanel: function () { },
    fullscreenButton: true,
    onFullscreen: function () { },
    collapseButton: true,
    onCollapse: function () { },
    lockedButton: true,
    lockedButtonLabel: I18n.t("setting.lock_pos"),
    onLocked: function () { },
    refreshButton: true,
    refreshButtonLabel: I18n.t("setting.refresh_content"),
    onRefresh: function () { },
    colorButton: true,
    colorButtonLabel: I18n.t("setting.panel_style"),
    onColor: function () { },
    panelColors: ['bg-primary-700 bg-success-gradient',
      'bg-primary-500 bg-info-gradient',
      'bg-primary-600 bg-primary-gradient',
      'bg-info-600 bg-primray-gradient',
      'bg-info-600 bg-info-gradient',
      'bg-info-700 bg-success-gradient',
      'bg-success-900 bg-info-gradient',
      'bg-success-700 bg-primary-gradient',
      'bg-success-600 bg-success-gradient',
      'bg-danger-900 bg-info-gradient',
      'bg-fusion-400 bg-fusion-gradient',
      'bg-faded'
    ],
    resetButton: true,
    resetButtonLabel: I18n.t("setting.reset_content"),
    onReset: function () { }
  });
}


/**
 * @function fnCloseDialog. vishal 2020/05/07
 * initialize panel
 * options.buttons : create button using fnGetSmartPanelButton function & pass string to object
 */
fnInitPanel = function (panel_id, options = {}) {
  buttons = options.buttons || ""
  $('#' + panel_id).smartPanel({
    localStorage: true,
    onChange: function () { },
    onSave: function () { },
    opacity: 1,
    deleteSettingsKey: '#deletesettingskey-options',
    settingsKeyLabel: 'Reset settings?',
    deletePositionKey: '#deletepositionkey-options',
    positionKeyLabel: 'Reset position?',
    sortable: false,
    buttonOrder: '%collapse% %fullscreen% %close%',
    buttonOrderDropdown: '%refresh% %locked% %color% %custom% %reset%' + buttons,
    customButton: false,
    customButtonLabel: "Custom Button",
    onCustom: function () { },
    closeButton: false,
    onClosepanel: function () { },
    fullscreenButton: true,
    onFullscreen: function () { },
    collapseButton: options.collapseButton == null ? false : options.collapseButton,
    onCollapse: function () { },
    lockedButton: false,
    lockedButtonLabel: "Lock Position",
    onLocked: function () { },
    refreshButton: options.refreshButton == null ? true : options.refreshButton,
    refreshButtonLabel: I18n.t("setting.refresh_content"),
    onRefresh: function () { },
    colorButton: false,
    colorButtonLabel: "Panel Style",
    onColor: function () { },
    panelColors: ['bg-primary-700 bg-success-gradient',
      'bg-primary-500 bg-info-gradient',
      'bg-primary-600 bg-primary-gradient',
      'bg-info-600 bg-primray-gradient',
      'bg-info-600 bg-info-gradient',
      'bg-info-700 bg-success-gradient',
      'bg-success-900 bg-info-gradient',
      'bg-success-700 bg-primary-gradient',
      'bg-success-600 bg-success-gradient',
      'bg-danger-900 bg-info-gradient',
      'bg-fusion-400 bg-fusion-gradient',
      'bg-faded'
    ],
    resetButton: true,
    resetButtonLabel: I18n.t("setting.reset_panel"),
    onReset: function () { }
  });
}

/**
 * @function fnGetSmartPanelButton. vishal 2020/05/07
 * get button for list option smartpanel
 * obj.title : title of button
 * obj.href  : link of title
 * obj.class : add class name
 * obj.id    : add id name
 */
fnGetSmartPanelButton = function (obj) {
  // Create anchor element.
  var a = document.createElement('a');
  // Create the text node for anchor element.
  var text = document.createTextNode(obj.title);
  // Append the text node to anchor element.
  a.appendChild(text);
  // Set the title.
  a.title = obj.title || "";
  // Set the href property.
  a.href = obj.href || "#";
  a.id = obj.id || "";
  a.classList = "dropdown-item pl-4 " + (obj.class || "")
  return a;
}

/**
 * @function setCookie. vishal 2020/05/07
 * you can set cookie
 */
setCookie = function (cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

/**
 * @function getCookie. vishal 2020/05/07
 * you can find cookie value by using key
 */
getCookie = function (cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

/**
 * @function delCookie. harsh 2021/10/28
 * you can delete cookie value by using key
 */
 delCookie = function(cname) {
    setCookie(cname,"",-1);
 }

// added by Dinesh on 20 May 2020, convert any link into ajax call,
// add the class js-ajax-link and to replace the text of link after success,
// add the data-success attribute
linkWithAjax = function () {
  var go_to_next = true;
  var link = '.js-ajax-link',
    $this, href, method, last_html, normal_text, remove_spinner = false;
  if ($(link)[0]) {

    $(link).click(function () {
      $this = this;

      // only aaplicable on profile link/unlink accounts
      if($(this).hasClass('inactive-link')){
        return false;
      }

      if($(this).data('method') == 'delete' || $(this).hasClass('confirm') ){
        go_to_next = false;
          Swal.fire({
              title: I18n.t("C0001"),
              text: I18n.t("I0039"),
              type: "warning",
              showCancelButton: true,
              confirmButtonText: I18n.t("button.yes_"),
              confirmButtonAriaLabel: I18n.t("button.yes_"),
              cancelButtonText: I18n.t("button.no_"),
              cancelButtonAriaLabel: I18n.t("button.no_"),
          }).then(function(result) {

              if(result.value){
                fireAjaxForLink($this);
                $($this).addClass('inactive-link');
              }
          })
      }else{
          fireAjaxForLink($this);
      }

      return false;
    });
  }
  return false;
}

fireAjaxForLink = function($this){
    $this = $($this);
    last_html = $this.html();
    href = $this.attr("href");
    if (typeof (href) != 'undefined' && href != null && href != '#') {
      method = $this.data("method");
      if (typeof (method) == 'undefined' || method == '')
        method = 'get';
      $this.html($this.html() + '&nbsp<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>');
      $this.attr("disabled", true);
      normal_text = $this.data('success');
      $.ajax({
        method: method,
        url: href,
        success: function (resp) {
          afeterAjaxSuccess($this, resp);
          if (resp.info != null) {
            toastr.success(resp.info);
            $this.html(normal_text);
            $this.attr("disabled", false);
          } else if (resp.error) {
            toastr.error(resp.info);
            $this.html(last_html);
            $this.attr("disabled", false);
          }
        },
        error: function (resp) {
          $this.html(last_html);
          $this.attr("disabled", false);
        }
      });
    }
}

// added by Dinesh on 20 May 2020, it handle after success response of ajax,
afeterAjaxSuccess = function ($this, resp) { }

/*
notifications for whole site, by Dinesh on 21 May 2020
*/
fnNotification = function ($this) {
  var arial = $($this).attr('aria-expanded');
  var notifications = window.notifications;
  var notifications_wraper = "#js-list-latest-nitification";

  if (arial == 'true')
    return false;

  if (notifications.length > 0) {
    $.ajax({
      url: '/notifications/list',
      type: 'post',
      data: {
        messages: notifications,
        count: notifications.length
      },
      success: function (resp) {
        $(notifications_wraper).html(resp)
          .addClass('notification-loading');
      }
    });
  }

}

/**
 * @function fnCopyToClipboard. vishal 2020/05/21
 * copy text to clipboard
 <script>
(function(i,s,o,g,r,a,m){ i['FesWidgetObject']=r;i[r]=i[r]||function(){ (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o), m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m);})(window,document,'script','http://stg-fesfans.work/widget.js?email=YOUR-EMAIL&access_token=YOUR-TOKEN','fes');
</script>
 */
fnCopyToClipboard = function (id) {
  var from = document.getElementById(id);
  var range = document.createRange();
  window.getSelection().removeAllRanges();
  range.selectNode(from);
  window.getSelection().addRange(range);
  document.execCommand('copy');
  window.getSelection().removeAllRanges();
  toastr.success(I18n.t('I0008'));
}

// transform cropper dataURI output to a Blob which Dropzone accepts
dataURItoBlob = function (dataURI) {
  var byteString = atob(dataURI.split(',')[1]);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], {
    type: 'image/jpeg'
  });
}

fnOpenWidgetBox = function (domain_name,widget_param) {
  blockcode = "<div class='view-code-box widget'><p class='mb-0'><p id='widgetcodeblock'>&lt;script&gt;<br>(function(v,e,b,u,i,n,p){v['FesWidgetObject']=i;v[i]=v[i]||function(){ (v[i].u=v[i].q||[]).push(arguments)},v[i].l=1*new Date();n=e.createElement(b), p=e.getElementsByTagName(b)[0];n.async=1; n.type='application/javascript'; n.src=u+'&v='+window.localStorage.getItem('"+widget_param+"');p.parentNode.insertBefore(n,p);})(window,document,'script','" + domain_name + "/widget.js?token=access_token','bniti'); <br>&lt;/script&gt;</p></p>";

  Swal.fire({
    title: I18n.t("widget.admin.title"),
    type: "info",
    html: blockcode,
    showCloseButton: true,
    confirmButtonText: I18n.t("menu.copy")
  }).then((result) => {
    if (result.value==true) {
     fnCopyToClipboard("widgetcodeblock");

    {
  blockcode1 = "<div class='view-code-box widget'><p class='mb-0'";
  blockcode1 += "<ul>";
  blockcode1 += "<li>" + I18n.t("widget.guide.step_1") + "</li>";
  blockcode1 += '<li> ' + I18n.t("widget.guide.step_2") + ' &lt;div id="bniti-widget-element-all" div&gt; &lt;/div&gt; ' + I18n.t("widget.guide.step_3") + '.</li>';

  blockcode1 += "<li> " + I18n.t("widget.guide.step_4") + " </li>";
  blockcode1 += "<li> " + I18n.t("widget.guide.step_5") + " </li>";
  blockcode1 += "</ul>";
  blockcode1 += "</div>";
Swal.fire({
    title: I18n.t('widget.guide.title'),
    type: "info",
    html: blockcode1,
    showCloseButton: true,
    confirmButtonText: I18n.t('button.ok')
  })
}
}
  });
}


var maxLength = 100;
fnSetReadMore = function(rcount, $this, resp){

  var myStr = $($this).html();
  var textStr = $($this).text();
  if ($.trim(textStr).length > maxLength) {
    var newStr = textStr.substring(0, maxLength);
    var removedStr = textStr.substring(maxLength, myStr.length);
    $($this).empty().html(newStr);
    $($this).append('<span class="more-text mreadmore-'+rcount+'">' + myStr + '</span>');

    var read_btns = '<a href="javascript:void(0);" data-id="'+rcount+'" class="read-more">'+I18n.t('common.read_more')+'</a>';
       read_btns += '<a href="javascript:void(0);" data-id="'+rcount+'" class="read-less" Style="display:none;">'+I18n.t('common.read_less')+'</a>';
    $($this).next('.panel-bottom-center').append(read_btns);
  }
}

fnShowReadMore = function(resp) {
    var rcount = 0;
    if($('.read-less')[0])
      $('.read-less').remove();
    $('.show-read-more').each(function() {
        rcount++;
        var $this = $(this).closest('.panel-content');
        $this.find('.read-more').remove();
        $this.find('.read-less').remove();
        fnSetReadMore(rcount, this, resp);
        fnReadMoreClick($this);
    });
}

fnReadMoreClick = function($this){
  var read_more = $($this).find('.read-more');
  read_more.click(function() {
      var mid = $(this).data('id');
      $(this).closest('.panel-content').find('.show-read-more').html($(".more-text.mreadmore-"+mid).contents());
      $(this).html(I18n.t('common.read_less'));

      var $this = $(this).closest('.panel-content');
      $(this).hide();

      $this.find('.read-less').show();
      fnReadLessClick($this);
  });
}
// =========================================== function end ======================================//
$(document).ready(function(){
  if($('.show-read-more')[0])
  fnShowReadMore()
})

setCookiesForLinkAccount = function() {
  setCookie("link_social_accounts", true, 365);
}

fnReadLessClick = function($this){
  var read_less = $($this).find('.read-less');
  $('.read-less').click(function(){
    var $this1 = $(this).closest('.panel-content');
    var $this2 = $(this).closest('.panel-content').find('.show-read-more');
    var rcount = parseInt($(this).data('id'));
    $this1.find('.read-more').remove();
    $this1.find('.read-less').remove();
    fnSetReadMore(rcount, $this2, 1);
    fnReadMoreClick($this1);
  })
}

/**
 * @function fnToggleExpandCollapse. Jay 2021/06/17
 * handle panel toggling + / - icon shows
 */
fnToggleExpandCollapse = function(){
  $(".panel-toolbar button i.fa-custom-icon").on('click', function(e){
    if ($(this).closest('.panel').hasClass('panel-collapsed')){
      $(this).closest('.panel').removeClass('panel-collapsed');
    }else{
      $(this).closest('.panel').addClass('panel-collapsed');
    }
  })
}

fnTwoWayAuth = function(otp){
  if (otp != null && otp == 'false') {
    var twf = getCookie('MonjuTwoFactor_'+ window.btoa(window.fes_uid));
    var cancel = getCookie('MonjuTwoFactorIgnore');
    if (((window.fes_tfs == "false")) && ((cancel == '')) && !(window.location.href.indexOf("profile") > -1)){
      setTimeout(function() {
        Swal.fire({
            customClass: {title: 'two-factor-title'},
            title: I18n.t('twf_popup.title'),
            html: I18n.t('twf_popup.text'),
            width: 600,
            showCancelButton: true,
            showCloseButton: true,
            allowOutsideClick: false,
            confirmButtonColor: "#886ab5",
            confirmButtonText: I18n.t('twf_popup.btn_yes'),
            cancelButtonText: I18n.t('twf_popup.dont_show_again')
        }).then(function(result){
            if (result.value != null){
              window.open("/user_profile");
              $(".two-factor-auth").trigger("click");
              fnLastPasswordUpdate(window.fes_user_last_password_at)
            }else if (result.dismiss == 'cancel'){
              fes_uid = fes_uid
              $.ajax({
                type: "patch",
                url: '/update_2fa_status',
                dataType: 'script',
                data: { fes_uid: fes_uid},
                success: function (data) {
                  dialogue_visble = false;
                }
              });
            }else{
              setCookie('MonjuTwoFactorIgnore','Yes');
              // fnLastPasswordUpdate(window.fes_user_last_password_at)
            }
        });
      }, 1000);
      $(document).on('change','#swal2-checkbox', function(e) {
        if ($(this).is(':checked') == true){
          setCookie('MonjuTwoFactor_'+ window.btoa(window.fes_uid),'No',2);
          setTimeout(function() {$(".swal2-cancel").trigger("click");}, 500);
        }else{
          delCookie('MonjuTwoFactor_'+ window.btoa(window.fes_uid));
        }
      });
    }
  }
}
//This function is for check each user last_update_password after login.
fnLastPasswordUpdate = function(last_password){
  if (last_password != null && last_password != undefined) {
    var last_update_pwd = (window.fes_user_last_password_at)
    var last_date = new Date(last_update_pwd)
    var last_month = last_date.getMonth()+1;
    var current_date = new Date();
    var current_month = current_date.getMonth()+1;
    var get_month = (current_month - last_month)
    if(get_month == 3){
      setTimeout(function() {
        Swal.fire({
            customClass: {title: 'two-factor-title'},
            title: I18n.t('password_popup.title'),
            text: I18n.t('password_popup.text'),
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonColor: "#886ab5",
            confirmButtonText: I18n.t('password_popup.btn_yes'),
            cancelButtonText: I18n.t('password_popup.btn_cancel')
        }).then(function(result){
            if (result.value != null){
              // fnOpenDialog('change_user_password', {uid: window.fes_uid});
            }
            else{
            }
        });
      }, 1000);
    }
  }
}

fnContactNumber = function(evt){
  var charCode = (evt.which) ? evt.which : event.keyCode;
  if (charCode != 46 && charCode != 45 && charCode > 31 && (charCode < 48 || charCode > 57)){
    return false;
  }
  return true;
}

fnBrandSelect2 = function(id){
  if (I18n.locale == "jp"){
    var key = ["Brand-JP"];
  }
  else if (I18n.locale == "en"){
    var key = ["Brand-EN"];
  }
  var placeholder = [I18n.t('common.select_label', {p0: I18n.t('menu.brand')})];
  var condition = [[{}]];
  var validate = [false];
  var obj = {'id': id, 'key': key, 'placeholder': placeholder, 'condition': condition, validate: validate};
  fnInitSelect2_tmp(obj);
}

